export const STRAPI_LINK = 'https://refined-basketball-80457f5c40.strapiapp.com'

function replacePolishLetters(str: string) {
  const polishLetters = {
    'ą': 'a',
    'ć': 'c',
    'ę': 'e',
    'ł': 'l',
    'ń': 'n',
    'ó': 'o',
    'ś': 's',
    'ż': 'z',
    'ź': 'z',
    'Ą': 'A',
    'Ć': 'C',
    'Ę': 'E',
    'Ł': 'L',
    'Ń': 'N',
    'Ó': 'O',
    'Ś': 'S',
    'Ż': 'Z',
    'Ź': 'Z'
  };

  return str.split('').map(char => polishLetters[char] || char).join('');
}

export const slugify = (text: string) => {
  if(!text) return ""
    return replacePolishLetters(text)
      .toLowerCase()
      .replace(/\s+/g, '-')           // Replace spaces with -
      .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
      .replace(/\-\-+/g, '-')         // Replace multiple - with single -
      .replace(/^-+/, '')             // Trim - from start of text
      .replace(/-+$/, '');            // Trim - from end of text
  };

export const photoUrlPrep = (url: string) => {
    return `${url}`
}
